import React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Description from '../components/description'

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={styles.container}>
        <h1 className="has-text-primary" style={styles.heading}>
          404
        </h1>
        <Title variant="h2" content="Página no encontrada" />
        <Description
          content="Lo sentimos, la página que has solicitado no ha sido encontrada."
          emoji="🔍"
          emojiLabel="glass emoji"
        />
        <Link style={styles.link} className="button cta" to="/">
          Volver a la página de inicio
        </Link>
      </div>
    </Layout>
  )
}

const styles = {
  container: {
    minHeight: '100vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    margin: 'auto',
    justifyContent: 'center',
    padding: '1rem',
  },
  heading: {
    fontSize: '10rem',
    fontWeight: 'bold',
  },
  link: {
    marginTop: '10px',
  },
}

export default NotFoundPage
